<script>
  import { link, location } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import IoIosClose from 'svelte-icons/io/IoIosClose.svelte';

  import navegacaoStore from '../store';

  let dados;
  let width;

  navegacaoStore.subscribe((dadosStore) => {
    dados = dadosStore;
  });

  const toggleSidebar = (mob = false) => {
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, sidebarAberta: !dados.sidebarAberta };
    });
  };
  const updatePaginaAtual = () => {
    width < 767 && toggleSidebar();
    navegacaoStore.update((dadosStore) => {
      return { ...dadosStore, paginaAtual: Number($location.split('/')[1]) };
    });
  };
</script>

<svelte:window bind:innerWidth={width} />
<div
  class={dados.sidebarAberta ? 'fundo-mobile active' : 'fundo-mobile'}
  on:click={toggleSidebar}
/>

<style>

@media (min-width:700px){
  #fixo{
    display:none;
  }
  /* Teste Vercel */
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(182, 182, 182, 0);
    border-radius: 10px;
    padding: 5px 0;
  }
}


  .sidebar {
    /* padding: 5px; */
    padding: 10px 2px;
    position: fixed;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--COR_MODULO);
    border-radius: 0 20px 20px 0;
    z-index: 100;
    box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 200ms ease;
    width: 90px;
    transform: translateY(-50%);
    top: 50%;
    overflow-x: hidden;
  }

  .sidebar:hover ::-webkit-scrollbar-thumb {
    background: rgba(182, 182, 182, 0.308);
  }

  a .titulo {
    position: absolute;
    width: 100px;
    margin-left: 18px;
    color: #000;
    left: -20px;
    transition: left 200ms ease, opacity 100ms ease;
    opacity: 0;
    font-size: 14px;
    z-index: 201;
  }

  .sidebar.open {
    width: 196px;
    left: 0;
  }

  .sidebar.open a .titulo {
    left: 50px;
    opacity: 1;
    transition: left 100ms ease, opacity 200ms ease;
    font-family: var(--FONT_CABIN);
    color: #fff;
    text-transform: uppercase;
  }

  a {
    padding: 6px 5px;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 9px;
    text-decoration: none;
  }
  a .icone {
    position: relative;
    z-index: 202;
  }
  a svg {
    /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2)); */
    width: 52px;
    height: 52px;
    transition: all 100ms ease;
    /* margin-left: 3px; */
  }

  .unidade svg {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
    width: 52px;
    height: 52px;
    transition: all 100ms ease;
    margin-left: 0px;
  }

  .numero-icone {
    position: absolute;
    left: 24px;
    color: var(--COR_MODULO);
    padding-top: 2px;
    font-size: 24px;
    font-family: var(--FONT_CABIN);
    font-weight: 700;
    z-index: 230;
  }
  .sidebar-content a:nth-child(3) .numero-icone {
    left: 26px;
  }
  .toggle-menu {
    display: flex;
    font-size: 13px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 7px 5px 5px 5px;
    cursor: pointer;
    transition: all 200ms ease;
    user-select: none;
  }
  .toggle-menu .fechar {
    display: none;
  }
  .sidebar.open .toggle-menu .fechar {
    display: inline-block;
  }
  .sidebar.open .toggle-menu .abrir {
    display: none;
  }
  .sidebar.open .toggle-menu {
    flex-direction: row;
  }
  .toggle-menu .icone {
    display: flex;
    transform: rotateY(180deg);
    transition: all 200ms ease;
  }
  .sidebar.open .toggle-menu .icone {
    transform: rotateY(0);
  }
  .toggle-menu .titulo {
    margin: 0 8px;
    color: #fff;
  }

  .path-brasao {
    fill: #fff;
  }

  .sidebar.open a:hover .titulo,
  .sidebar :global(a.active .titulo) {
    color: var(--COR_MODULO_FRACO);
    /* font-weight: 700; */
  }
  .sidebar :global(a.active .path-brasao),
  .sidebar :global(a:hover .path-brasao) {
    fill: var(--COR_MODULO_FRACO);
  }

  .sidebar :global(a:hover .path-brasao-deg) {
    fill: var(--COR_MODULO_FRACO);
  }

  .hamburger {
    display: none;
  }

  .fundo-mobile {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: rgba(97, 97, 97, 0.281);
    z-index: 100;
    display: none;
  }

  .fechar-mobile {
    display: none;
  }

  /* .gradiente {
    fill: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  } */

  @media (max-width: 800px) {
    .hamburger {
      position: fixed;
      top: 20px;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      z-index: 99;
      background: linear-gradient(to right, var(--COR_MODULO_FRACO), var(--COR_MODULO));
      border-radius: 10px;
      box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.9);
      cursor: pointer;
    }
    .hamburger svg {
      fill: #fff;
    }
    .sidebar {
      left: -150px;
    }
    .fundo-mobile.active {
      display: block;
    }
    .sidebar.open .fechar-mobile {
      left: 0;
    }
    .fechar-mobile .icone {
      width: 30px;
    }
  }

  .sidebar-content {
    width: 100%;
  }

  .sidebar-content {
    overflow-y: scroll;
    overflow-x: hidden;
  }

</style>
